@import 'libs/theming/src/lib/assets/styles/imports/vars';
@import 'libs/theming/src/lib/assets/styles/imports/clr-color-vars';

// Header
:root {
	--clr-header-bg-color: #595959 !important;

	// overwrite clarity using brown for orange badges (at least in version 13.14.1)
	--clr-badge-orange-bg-color: #{$orange-dark} !important;
	--clr-label-orange-bg-color: #{$orange-dark} !important;
}

/**
 * [NOTE] global styles to be used by this application
 */

// increase specificity to always not display element
.XGhidden {
	display: none !important;
}

// overwrite a clarity icon style, by using global class to add a gradient, that is specified in the component using it
.XGiconwrapper svg {
	fill: url('#XCiconwrapper-gradient');
}

// TODO - move column switch fix to mac
// overwrite z-index for column switch to be visible in modal over datagrid
.column-switch.clr-popover-content {
	z-index: 5000 !important; // this style is proposed by `.clr-popover-content`, but overwritten from `.column-switch`, both clarity classes
}

// overwrite z-index for modal dropdown. In another case dropdown opens buttons not at foreground. See task WEB-317
.dropdown-menu.clr-popover-content {
	z-index: 5001 !important;
}

// we need global classes for overwriting svg-frontends-application-header color in normalMode and editMode
.XGheader {
	&__staff-customer clr-header {
		background-color: $header-background-user;
	}

	&__staff-customer--editmode clr-header {
		background-color: $header-background-edit;
	}
}
